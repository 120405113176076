import React from 'react';
import Form from './Form';

function LandingPage() {
  return (
    <div className="px-4 md:px-15 bg-[#f1e9cf]" itemScope itemType="https://schema.org/WebPage">
      {/* Section 2 : Explication sur la réflexologie */}
      <section id="reflexologie" className="pt-20 -mt-16" itemScope itemType="https://schema.org/Service">
        <h1 className="text-3xl font-bold text-center mb-8" itemProp="name">Être Réflexologue</h1>

        <div className="flex flex-col md:flex-row justify-center items-center mx-auto my-2">
          <div
            className="w-full md:w-1/3 p-6 bg-white rounded-lg shadow-md mx-4"
            itemScope
            itemProp="hasOfferCatalog"
            itemType="https://schema.org/ItemList"
          >
            <h2 className="font-semibold mb-2" itemProp="name">Qu'est-ce que c'est ?</h2>
            <p className="mb-4" itemProp="description">
              La réflexologie, pratiquée par les réflexologues, est une thérapie douce qui vise à équilibrer le corps et à promouvoir le bien-être. 
              Elle se base sur le principe que certaines zones des pieds et des mains sont liées à des organes et systèmes du corps.
            </p>
            <h2 className="font-semibold mb-2">Bienfaits d'une séance avec un réflexologue:</h2>
            <ul className="list-disc list-inside">
              <li itemProp="itemListElement">Réduction du stress</li>
              <li itemProp="itemListElement">Amélioration de la circulation sanguine</li>
              <li itemProp="itemListElement">Récupération après un effort physique</li>
            </ul>
          </div>

          <div className="w-full md:w-1/4 mx-4 my-2 flex justify-center">
            <img
              src="/pied-1.jpg"
              alt="Reflexologue"
              className="w-full rounded-2xl"
              itemProp="image"
            />
          </div>
        </div>
      </section>

      {/* Section 3 : Qui suis-je ? */}
      <section id="qui-suis-je" className="pt-20 -mt-16" itemScope itemType="https://schema.org/Person">
        <h1 className="text-3xl font-bold text-center mb-8">
          <span itemProp="name">Claudine Bouet</span>, Réflexologue entre Bayeux et Caen
        </h1>

        <div className="flex flex-col md:flex-row justify-center items-center">
          <div className="w-full md:w-1/4 mx-2 my-2">
            <img
              src="/claudine-bouet-reflexologue-caen-bayeux.jpg"
              alt="Claudine Bouet Reflexologue Caen Bayeux"
              className="w-full rounded-2xl h-auto"
              itemProp="image"
            />
          </div>
          <div className="w-full md:w-1/2 p-6 bg-white rounded-lg shadow-md mx-2 my-2">
            <h2 className="font-semibold mb-2">Qui suis-je ?</h2>
            <p className="mb-4" itemProp="description">
              Passionnée de médecine douce depuis très longtemps, il a été normal, il y a 8 ans, que je me tourne pour soulager mon mal de dos vers la réflexologie plantaire. Les séances rapprochées travaillent mes douleurs qui s’estompent. Très vite, je n’ai plus besoin que de séances plus éloignées pour entretenir ma santé et me détendre… je les attends toujours avec impatience !
              Aussi, quand la vie me donne l’occasion de me former et de devenir réflexologue, je n’hésite pas : la réflexologie plantaire offre tellement de bien-être et de soulagement que c’est décidé, moi aussi je veux faire du bien autour de moi !
              Je me forme pendant 8 mois, selon la méthode INGHAM, alternant les cours théoriques et la pratique sur des pieds volontaires « connus » tout d’abord puis bien vite sur des « nouveaux » pieds, le bouche à oreille ayant fonctionné !
              Les retours positifs ne se font pas attendre : meilleur sommeil, détente, soulagement des douleurs, et les enfants ne sont pas en reste avec une meilleure concentration et des maux de ventre envolés…
              En complément, je suis aussi des cours de diététique et de nutrition, convaincue depuis toujours que notre santé se trouve aussi dans notre assiette. Biochimiste de formation, ces cours complètent mes connaissances et me permettent de conseiller une alimentation saine et équilibrée, abordable par tous.
              Installée entre bayeux et Caen, je suis heureuse d’apporter dans la vie des personnes qui viennent me voir du soulagement, de la détente et du bien-être…
            </p>
          </div>
        </div>
      </section>

      {/* Section 4 : Bien-être au travail */}
      <section id="bien-etre-au-travail" className="pt-20 -mt-16">
        <h1 className="text-3xl font-bold text-center mb-8">Réflexologie au travail</h1>
        <div className="flex flex-wrap justify-center space-x-4">
          <div className="md:w-1/4 p-6 bg-white rounded-lg shadow-md mb-4" itemScope itemType="https://schema.org/Service">
            <h2 className="text-xl font-semibold mb-4" itemProp="name">Les enjeux du stress</h2>
            <p itemProp="description">
              C’est prouvé, le stress est la première source d’arrêt maladie. Mal géré, il peut dégénérer en dépression. 
              Au travail, les situations stressantes sont nombreuses : le bruit, les responsabilités, les objectifs, la cohabitation… 
              Ces tensions peuvent entraîner des maux de dos, des troubles du sommeil et de l'irritabilité. Un réflexologue saura traiter ces symptômes.
            </p>
          </div>

          <div className="md:w-1/4 p-6 bg-white rounded-lg shadow-md mb-4" itemScope itemType="https://schema.org/Service">
            <h2 className="text-xl font-semibold mb-4" itemProp="name">Bénéfices pour l’employeur</h2>
            <p itemProp="description">
              Le réflexologue aide vos collaborateurs à gérer leur stress. Ils se sentent reconnus et épaulés, 
              sachant qu'ils appartiennent à une entreprise soucieuse de leur bien-être.
            </p>
          </div>

          <div className="md:w-1/4 p-6 bg-white rounded-lg shadow-md mb-4" itemScope itemType="https://schema.org/Service">
            <h2 className="text-xl font-semibold mb-4" itemProp="name">Comment procéder ?</h2>
            <p itemProp="description">
              Je me déplace dans la région de Caen dans votre entreprise avec un fauteuil confortable, une couverture moelleuse et de la musique douce.
              Des séances découverte de 20 minutes et des séances complètes de 55 minutes sont proposées.
              La fréquence peut varier selon vos besoins : hebdomadaire, mensuelle, ou lors d'événements spéciaux.
            </p>
          </div>
        </div>
      </section>

      {/* Section 5 : Contact */}
      <section id="contact" className="pt-20 -mt-16" itemScope itemType="https://schema.org/ContactPoint">
        <h1 className="text-3xl font-bold text-center mb-8">Réserver une séance de réflexologie</h1>
        <Form />
      </section>
    </div>
  );
}

export default LandingPage;
